import React, { useState, useEffect } from "react";
import "./EventBox.css"; // Optional: Create a separate CSS file for component-specific styles
import { FaMapMarkerAlt } from "react-icons/fa";

const EventBox = ({
  event_title,
  image,
  start_date,
  end_date,
  address,
  event_link,
}) => {
  useEffect(() => {
    console.log("Image URL:", image);
  }, [image]);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculateProgress = () => {
      const start = new Date(start_date);
      const end = new Date(end_date);
      const now = new Date();
      const totalDuration = end - start;
      const timeLeft = end - now;
      return Math.max(0, (timeLeft / totalDuration) * 100);
    };

    const updateProgress = () => {
      setProgress(calculateProgress());
    };

    updateProgress(); // Initial calculation
    const intervalId = setInterval(updateProgress, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [start_date, end_date]);

  const categorizeEvent = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const now = new Date();
    const totalDuration = end - start;
    const elapsed = now - start;
    const progress = (elapsed / totalDuration) * 100;

    if (progress > 50) {
      return "moreThanHalfway";
    } else if (progress > 0) {
      return "lessThanHalfway";
    } else {
      return "notStarted";
    }
  };

  const category = categorizeEvent(start_date, end_date);

  const formatLocalDate = (dateString) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  return (
    <>
      <a href={event_link} target="_blank" rel="noopener noreferrer">
        <div
          className={`event-box ${
            category === "moreThanHalfway" ? "red-glow" : ""
          } ${image ? "with-background" : ""}`}
          style={{
            // "--event-background-image": `url(${require("../Assets/Images/sampleImg.png")})`,
            "--event-background-image": `url(${image})`,
          }}
        >
          <div className="progress-bar-container">
            <span className="date">{formatLocalDate(start_date)}</span>{" "}
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${100 - progress - 2}%` }}
              >
                {progress < 85 && (
                  <span className="progress-percent">
                    {Math.round(100 - progress)}%
                  </span>
                )}
              </div>
            </div>
            <span className="date">{formatLocalDate(end_date)}</span>{" "}
          </div>
          {/* <div className="event-details">
            <h3>{event_title}</h3>
            <div className="event-location">
              <FaMapMarkerAlt /> {address}
            </div>
          </div> */}
          <div className="bottom-modal event-details">
            <h3>{event_title}</h3>
            <div className="event-location">
              <FaMapMarkerAlt /> {address}
            </div>
          </div>
        </div>
      </a>
    </>
  );
};

export default EventBox;
