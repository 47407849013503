import React, { useEffect, useState } from "react";
import "./App.css";
import EventBox from "./Components/EventBox";
import Modal from "./Components/Modal"; // Import the Modal component

const App = () => {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true); // State to control modal visibility

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          "https://2uf539q048.execute-api.us-east-1.amazonaws.com/events"
          // "https://xk3jm2o25k.execute-api.us-east-1.amazonaws.com/events"
        );
        const data = await response.json();
        const sortedEvents = data.sort(
          (a, b) => new Date(a.end_date) - new Date(b.end_date)
        );

        setEvents(sortedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [selectedCategory, setSelectedCategory] = useState("all");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const categorizeEvent = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const now = new Date();
    const totalDuration = end - start;
    const elapsed = now - start;
    const progress = (elapsed / totalDuration) * 100;

    if (progress > 50 && progress < 75) {
      return "moreThanHalfway";
    } else if (progress < 50) {
      return "lessThanHalfway";
    } else {
      return "notStarted";
    }
  };

  const filterEvents = (events, category) => {
    if (category === "all") return events;
    return events.filter(
      (event) => categorizeEvent(event.start_date, event.end_date) === category
    );
  };

  return (
    <div className="App">
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />{" "}
      {/* Add the Modal component */}
      {isMobile ? (
        <div className="category-dropdown">
          <select onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="all">PICK FOR ME 🎲</option>
            <option value="lessThanHalfway">WANDER OVER ⏳</option>
            <option value="moreThanHalfway">GO NOW 🚀</option>
          </select>
        </div>
      ) : (
        <div className="category-buttons">
          <button onClick={() => setSelectedCategory("all")}>
            PICK FOR ME 🎲
          </button>
          <button onClick={() => setSelectedCategory("lessThanHalfway")}>
            WANDER OVER ⏳
          </button>
          <button onClick={() => setSelectedCategory("moreThanHalfway")}>
            GO NOW 🚀
          </button>
        </div>
      )}
      <div className="event-list">
        {filterEvents(events, selectedCategory).map((event, index) => (
          <EventBox key={index} {...event} />
        ))}
      </div>
    </div>
  );
};

export default App;
