import React, { useEffect, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import "./Modal.css"; // Ensure this file contains the necessary styles

const Modal = ({ isOpen, onClose }) => {
  // Add state to manage the email input
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would send the email to your backend
    console.log("Email submitted:", email);
    // Reset the email input
    setEmail("");
  };

  const [state, handleSubmitForm] = useForm("movqvrng");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.className === "modal-overlay") {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-icon" onClick={onClose}>
          &times;
        </span>
        <h1 className="modal-header">Welcome to Up Next ATX!</h1>
        <div className="modal-body">
          <img
            src={require("../Assets/Images/founder_img_zoomed.png")}
            alt="Founders"
            className="founder-image"
          />
          <div className="modal-text">
            <p>
              Hi! We’re Spencer & Grace 👋 We created{" "}
              <span
                style={{
                  fontFamily: "Gill Sans MT, sans-serif",
                  fontWeight: "bold",
                }}
              >
                UpNextATX
              </span>{" "}
              to bring spontaneity back into the world! 🌟 If you’re tired of
              over-planning and just want to enjoy what’s happening right now,
              join us on this mission. Discover Austin like never before with
              real-time deals that lead you to hidden gems and local favorites.
              Spot a promotion, act quickly, and dive into unforgettable
              experiences. The best moments are the ones you don’t plan!
            </p>
            {state.succeeded ? (
              <p>Thanks for joining!</p>
            ) : (
              <form className="signup-form" onSubmit={handleSubmitForm}>
                <p className="signup-text">Sign up to hear more</p>
                <div className="signup-row">
                  <label htmlFor="email">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="signup-input"
                      placeholder="Enter your email"
                    />
                  </label>
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                  <button
                    type="submit"
                    className="signup-button"
                    disabled={state.submitting}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
